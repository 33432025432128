.md-check{
  cursor: pointer;
  padding-left: 1.25rem;
  margin: 0;
  display: inline-block;
  position: relative;
  input{
    position: absolute;
    cursor: pointer;
    z-index: 1;
    opacity: 0;
    &:checked + i:before{
      border-width: 0;
      background-color: inherit;
    }
    &:checked + span .active{
      display: inherit;
    }
    &[type="radio"] + i{
      &,
      &:before{
        border-radius: 50%;
      }
    }
    &[type="checkbox"]:checked + i:after{
      @include rotate(45deg);
      position: absolute;
      left: 6px;
      top: 2px;
      display: table;
      width: 6px;
      height: 12px;
      border: 2px solid;
      border-top: 0;
      border-left: 0;
      content: ' ';
    }
    &[type="radio"]:checked + i:after{
      position: absolute;
      left: 6px;
      top: 6px;
      display: table;
      width: 6px;
      height: 6px;
      background: #fff;
      border-radius: 50%;
      content: ' ';
    }
    &[disabled],
    fieldset[disabled] & {
      & + i:before{
        opacity: 0.5;
      }
    }
    &[disabled]:checked{
      & + i:before{
        opacity: 0.5;
      }
    }
  }
  > i{
    width: 0;
    height: 18px;
    line-height: 1;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    left: -1.25rem;
    top: -1px;
    &:before{
      content:"";
      position: absolute;
      width: 18px;
      height: 100%;
      border: 2px solid $input-border;
      border-radius: 2px;
    }
    &.no-icon:after{
      display: none !important;
    }
  }
}
