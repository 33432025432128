/*
badge 
*/

.badge{
  &.up{
    padding: 0.1565rem 0.245rem;
    position: relative;
    top: -0.75rem;
    left: -0.65rem;
    margin-right: -1rem;
    font-size: 70%;
    border: 1px solid rgba(255, 255, 255, 0.75);
    background-clip: padding-box;
    min-width: 1rem;
  }
  &.no-bg{
    background-color: transparent;
    color: inherit;
  }
}

.badge-sm{
  padding: .175em .35em;
  min-width: 0.85rem;
}

.badge-xs{
  padding: 0 !important;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 1rem;
  min-width: 0 !important;
  &:empty{
    display: inline-block;
  }
  &.md{
    width: 0.75rem;
    height: 0.75rem;
  }
  &.lg{
    width: 1rem;
    height: 1rem;
  }
}

.badge-o{
  border: 2px solid;
}
