@import "variables.scss";

.popover {
  z-index: 1040;
  max-width: 500px;
}

.container-fluid {
  padding: 0;
}


#productSearchContainer-external > .d-flex {
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid gray;
  margin-bottom: 1em;
}

#findNewProductModal .row
{
  margin: 0;
}

@media (min-width: 576px) {
 
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {

}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  #productSearchContainer-external .d-flex {
    flex-direction: row;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
 
}

