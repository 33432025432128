﻿.tryin-login{
  .navbar-brand{
    img, svg{
      display: block;
    }
  }
}


// centruje ikonu searche v topheadru
.navbar.navbar-expand-lg{
  .btn{
    height: 100%;}
}


// zarovnava logo doleva

.navbar-brand{
  width: 100%;
  img, svg{
    display: inline-block;
    width: 100px;
  }
}


.content-main {
  .content-main-bar{
    display: flex;
    justify-content: space-between;
  }
  & > div{
    &:first-child{
      flex-grow: 1;
    }
  }
  h2{
    margin-bottom: 20px;
  }
}

.userList{
  img.avatar{
    width: 50px;
    height: 50px;
  }
}





.breadcrumb{
  background: none;
  margin-bottom: 0;
  padding: 0 0;
  &-item{
    font-size: 12px;
    color: $primary;
    
  }
}


  .label{
    width: 100%;
  }


.form-box{
  padding: 20px;
  .form-row{
    .form-group{
      //margin-bottom: 0;
    }
    .btn{
      white-space: nowrap;
    }
  }
}

.form-group--label{
  input[type="file"]{
    display: none; }
}

.pagination{
  .page-item{
    .btn{
      height: auto;
    }
  }
}

.btn{
  height: 38px;
  padding: .5rem .9rem;
}
.btn-xs{
  padding: .375rem .75rem;
  height: auto;
  
}

.toggle.btn{
  border-radius: 50px;
  .toggle-handle{
    width: 36px;
    height: 36px;
    border-radius: 19px;
    
  }
}

.btn-reset-filter{
  background: transparent;
  border: 0;
  padding: 0;
  margin: 10px 0 0 0;
  color: red;
  cursor: pointer;
  i{
    margin-right: 5px;
  }
  &:hover{
    text-decoration: underline;
    background: transparent;
  }
}



select.form-control:not([size]):not([multiple]):not(.form-control-lg):not(.form-control-sm) {
  height: calc(2.25rem + 2px);
}



//zahlavi tabulek
.table{
  td{
    border-top: 1px solid rgba(120,130,140,0.1) !important;
    &:nth-child(2){
      font-weight: bold;
    }
    &:last-child{
      white-space: nowrap;
      text-align: right;
    }
    .form-control{
      min-width: 80px;
    }
  }
  thead{
    tr{
      th{
        border-bottom: 2px solid rgba(120,130,140, .15);
        line-height: 1.1;
        vertical-align: middle;
        &:hover{
          background: rgba(120,130,140, .05);;
        }
        a{ position: relative;
          padding-right: 20px;
          span{
            i{
              position: absolute;
              right: 0;
              top: 50%;
              transform: translateY(-50%);
              color: $gray-500 !important;
            }
          }
          
        }        
      }
    }
  }
}


.w-login{
  width: 400px;
  border-radius: 10px;
}

.content-main{
  //min-height: calc(100vh - 56px);
  position: relative;
  
}

.is-fullscreen .app-content .content-footer, .fixed-content .app-content .content-footer{
  display: block;
}


.text-theme,
.text-hover-theme a:hover,
.text-hover-theme .active > a {
  color: $primary !important; }

.nav-active-theme .nav-link.active,
.nav-active-theme .nav li.active > a,
.btn.theme,
.btn.b-theme:hover,
.btn.b-theme:focus,
.pace .pace-progress,
.theme {
  color: rgba(255, 255, 255, 0.85) !important;
  background-color: $primary !important;
}

.b-theme,
.nav-border,
nav.nav-border {
  border-color: $primary !important; }

.btn.theme-accent,
.btn.b-theme-accent:hover,
.btn.b-theme-accent:focus {
  color: rgba(255, 255, 255, 0.85) !important;
  background-color: $primary !important; }

.theme-accent {
  color: rgba(255, 255, 255, 0.85) !important;
  background-color: $primary !important; }

.sidenav .nav-border .active > a,
.sidenav .nav-active-text .active > a {
  color: $primary; }

.fill-theme {
  fill: $primary; }

.stroke-theme {
  stroke: $primary; }




#aside{
  .sidenav{
    form{
      button{
       background: transparent;
        -webkit-appearance: none;
        display: flex;
        width: 100%;
        border: 0;
        cursor: pointer;
        padding: 0 1.25rem;
        &:hover{
          background: rgba(255,255,255,0.065);
          .nav-text,
          .nav-icon{
            opacity: 1;
          }
        }
      }
    }

  }
}

.nav-pills{
  .nav-item{
    .nav-link{
     i{
       font-size: 16px;
     }
      &:hover{
        color: $primary;
      }
      &.active{
        &:hover{
          color: $white;
        }
      }
    }
  }
}
//
//
//.profile-form{
//  .ui-switch{
//    margin-top: 10px;
//    margin-left: .375rem;
//  }
//
//  .media{
//    .media-body{
//      h4{
//        display: inline-block;
//        position: relative;
//        transform: translateX(-.375rem);
//        input{
//          background: transparent;
//          border: 1px solid rgba(120,130,140, 0);
//          padding: 0.5rem 0.7rem;
//          border-radius: .25rem;
//          &:hover,
//          &:focus{
//            background: $white;
//            border: 1px solid rgba(120,130,140, 0.2);
//            box-shadow: inset 0 1px 1px rgba(0,0,0, 0.075);
//          }
//        }
//      }
//    }
//  }
//  .label{
//    margin-bottom: 0;
//    padding-left: .375rem;
//  }
//
//
//  .form-control{
//    background: transparent;
//    border: 0;
//    box-shadow: none;
//    color: $dark;
//    transition: all 100ms ease-in-out;
//    font-weight: bold;
//    transform: translate(-.375rem, -5px);
//    padding: .375rem .75rem !important;
//    &:hover,
//    &:focus{
//      background: $white;
//      border: 1px solid rgba(120,130,140, 0.2);
//      box-shadow: inset 0 1px 1px rgba(0,0,0, 0.075);
//      padding: .375rem .75rem;
//      font-weight: normal;
//      transform: translate(-.375rem, 0);
//    }
//  }
//  select{
//    &.form-control{
//      -webkit-appearance: none;
//      -moz-appearance: none;
//      appearance: none;
//      padding: .375rem .75rem ;
//      position: relative;
//      background: transparent url("../images/blank.svg") right center no-repeat;
//      background-size: 19px;
//      &:hover,
//      &:focus{
//        background: $white url("../images/angle-arrow-down.svg") right center no-repeat;
//        background-size: 19px;
//      }
//    }
//  }
//}

.dotbg{
  background: url("../images/login-bg.gif") center center no-repeat;
  background-size: cover;
}

.ui-switch i:before{
  background: $danger;
}

.spinner-border{
    border-width: 2px;
}


.sticky-top{
  z-index: 1000;
}

.modal{
  &-header{
    .close{
      background: $danger;
      position: absolute;
      right: 0;
      top: 0;
      border-radius: 50%;
      opacity: 1;
      width: 56px;
      height: 56px;
      span{
        display: block;
      }
      i{
        text-shadow: none;
        color: $white;
      }
      &:hover{
        opacity: 1 !important;
        background: darken($danger, 5%);
      }
    }
  }
  &-body{
    padding: 0;
    .table{
      td,th{
        &:first-child{
          padding-left: 15px;
        }
        &:last-child{
          padding-right: 15px;
        }
      }
    }
  }
  .input-group{
    select{
      &.form-control{
        border-radius: 0 !important;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        padding: .375rem .75rem ;
        position: relative;
        background: $white url("../images/angle-arrow-down.svg") right center no-repeat;
        background-size: 19px;
        &:hover,
        &:focus{
         
          background-size: 19px;
        }

      }
    }
  }
  &-product{
    display: flex;
    align-items: flex-start;
    padding: 15px;
    img{
      max-width: 100px;
      margin-right: 30px;
    }
    .h5{
      display: block;
      margin-bottom: 10px;
    }
    & > div{
      width: calc(100% - 300px);
    }
    .btn{
      white-space: nowrap;
      
    }
  }
}

.br-0{
  border-radius: 0 !important;
}


