/*
Streamline
*/
.streamline {
	position: relative;
	border-color: $border-color;
	@include clearfix();
	.sl-item:before,
	.sl-icon:before{
		content: '';
		position: absolute;
		border-color: inherit;
		border-width: 3px;
		border-style: solid;
		border-radius: 50%;
		width: 10px;
		height: 10px;
		top: 6px;
	}
}

.sl-icon{
	position: absolute;
	left: -5px;
	z-index: 2;
	border: inherit;
	border-width: 0;
	> i{
		width: 20px;
		height: 20px;
		line-height: 20px;
		font-size: 14px;
		text-align: center;
		border-radius: 10px;
		color: #fff;
		position: relative;
	}
	.streamline &:before{
		width: 20px;
		height: 20px;
		border-width: 10px;
		margin: 0 !important;
		top: 0 !important;
		left: 0;
	}
}

.sl-item{
	border-color: $border-color;
	position: relative;
	padding-bottom: 16px;
	clear: left;
	&:after{
		content: "";
		top: 18px;
		bottom: -4px;
		left: 4px;
		position: absolute;
		z-index: 1;
		border-color: inherit;
		border-left-width: 2px;
		border-right-width: 0;
		border-style: solid;
		.streamline-dotted &{
			border-style: dotted;
		}
	}
	&:last-child:after{
		display: none;
	}
	&.active{
		background-color: $border-color;
	}
}

.sl-left{
	float: left;
	position: relative;
	z-index: 1;
	margin-left: -7px;
	img{
		max-width: 24px;
	}
	+ .sl-content{
		margin-left: 30px;
	}
}

.sl-content{
	margin-left: 24px;
	p:last-child{
		margin-bottom:0;
	}
}

.sl-author{
	margin-bottom: 10px;
}

.sl-date{
	font-size: 0.85em;
}

.sl-footer{
	margin-bottom: 10px;
}

.streamline-xs{
	.sl-item{
		&:before{
			width: 7px;
			height: 7px;
			border-width: 2px;
			top: 7px;
		}
		&:after{
			left: 3px;
			border-left-width: 1px;
		}
	}
}
