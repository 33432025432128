
/*---------------------------
2. UI Components
---------------------------*/

@import "ui.animate.scss";
@import "ui.arrow.scss";
@import "ui.avatar.scss";
@import "ui.box.scss";
@import "ui.chat.scss";
@import "ui.check.scss";
@import "ui.layout.scss";
@import "ui.layout.boxed.scss";
@import "ui.list.scss";
@import "ui.loading.scss";
@import "ui.nav.scss";
@import "ui.nav.plus.scss";
@import "ui.nav.active.scss";
@import "ui.icon.scss";
@import "ui.item.scss";
@import "ui.page.scss";
@import "ui.plugin.scss";
@import "ui.streamline.scss";
@import "ui.switch.scss";
@import "ui.timeline.scss";
@import "ui.color.dark.scss";
@import "ui.color.scss";
