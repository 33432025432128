@import "bootstrap";

@import "../lib/@fortawesome/fontawesome-free/css/all.min.css";

@import "../lib/@ttskch/select2-bootstrap4-theme/select2-bootstrap4.min.css";

@import "../lib/nprogress/nprogress.css";
@import "../lib/tempusdominus-bootstrap-4/build/css/tempusdominus-bootstrap-4.min.css";

@import "../lib/bootstrap-datepicker/css/bootstrap-datepicker.min.css";

@import "../lib/bootstrap/css/bootstrap.min.css";

@import "../lib/select2/css/select2.min.css";

@import "../lib/croppie/croppie.css";

@import "app";

@import "tryin-header.scss";
//@import "tryin-datepicker.scss";

@import "../lib/codemirror/lib/codemirror.css";

@import "../lib/ekko-lightbox/ekko-lightbox.css";

@import "../lib/jquery-ui/themes/base/all.css";

html {
	overflow-y: hidden;
}
#productSearchContainer-external img {
	width: 200px;
	height: auto;
}

.userList img {
	width: 100px;
	height: 100px;
}

.numberCircle {
	display: inline-block;
	line-height: 0px;
	border-radius: 50%;
}

	.numberCircle span {
		display: inline-block;
		padding-top: 50%;
		padding-bottom: 50%;
		margin-left: 8px;
		margin-right: 8px;
	}

.prizeImage {
	position: relative;
	text-align: center;
}

	.prizeImage > .points {
		position: absolute;
		bottom: 8px;
		right: 8px;
	}

	.prizeImage > .status {
		position: absolute;
		bottom: 8px;
		left: 8px;
	}

.approvalCountIndicator {
	position: fixed;
	width: 100%;
	top: 0;
}

.borderless-t {
	border: 0;
	outline: 0;
	border-bottom: 1px solid black;
	background: transparent;
}

#previewImage {
	position: absolute;
	border: 1px solid #ccc;
	background: #333;
	padding: 5px;
	display: none;
	color: #fff;
	z-index: 9999;
}

.hover-bg {
	transition: background-color linear 0.3s;
	cursor: pointer;
}

.hover-bg-blue:hover {
	background-color: #53a6fa;
}

.hover-bg-gray:hover {
	background-color: #8a8a8a;
}

.fullwidth {
	width: 100%;
}

.image-checkbox {
	position: absolute;
	top: 10px;
	right: 30px;
}

.image-preview {
	height: 300px;
	width: 300px;
	object-fit: cover;
}

.imageContainer {
	position: relative;
	padding: 2px;
	transition: 0.3s ease;
	object-fit: fill;
	transform-origin: top;

	.topRight {
		position: absolute;
		top: 8px;
		right: 8px;
	}

	.menu {
		visibility: hidden;
		backface-visibility: hidden;
	}

	img {
		width: 50px;
		height: 50px;
	}

	.pending {
		border: 4px solid yellow;
	}

	.published {
		border: 4px solid green;
	}

	.blocked {
		border: 4px solid red;
	}
}

.mediarow {
	.published {
		border: 2px solid green;
	}

	.blocked {
		border: 2px solid red;
	}
}

.table {
	td {
		vertical-align: middle !important;
	}
}

.imageContainer:hover {
	opacity: 1;
	z-index: 9999;
	cursor: pointer;
	/** default is 1, scale it to 1.5 */
	transform: scale(1.5, 1.5) translate(0px, 5px);
	/** translate 50px from left, and 40px from top */
	/** transform: translate(50px, 40px); */
	/** combine both scale and translate */
	/** transform: scale(1.5, 1.5) translate(50px, 40px); */
	img {
		width: 100%;
		height: 100%;
	}

	.menu {
		visibility: visible;
		transform-origin: top right;
		transform: scale(0.67,0.67);
	}
}

.card a {
	display: flex;
	flex-direction: column;

	img {
		width: auto;
		height: 258px;
		align-self: center;
	}
}

.uww {
	overflow-wrap: break-word;
	word-break: break-all;
}

.remove-pic {
	position: absolute;
	left: 250px;
}

.title-label {
	font-size: 1.5rem;
}

.subtitle-label {
	font-size: 1rem;
}

.title {
	font-size: 1.5rem;
}

.select2-container {
	//width: 100% !important;
	display: block !important;
}

.imagesProductContainer {
	overflow-x: auto;
	white-space: nowrap;
	margin-bottom: 7px;

	img {
		height: 100px;
		width: 100px;
		display: inline-block;
		padding: 0px;
		border: 0px;
	}
}

.form-error input, .form-error select {
  background-color: $danger;
  color: $light-white;
}