/*
Layout
*/
html{
	height: 100%;
}

body{
    width: 100%;
    min-height: 100vh;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
}

.app{
  	width: 100%;
	display: -ms-flexbox;
    display: flex;
}

.app-header{
	position: fixed;
	z-index: 1040;
	left: 0;
	right: 0;
	~ .app{
		padding-top: $navbar-height;
		.app-aside{
			min-height: calc(100vh - #{$navbar-height});
		}
	}
}

.app-aside{
  	-ms-flex-negative: 0;
    flex-shrink: 0;
	position: relative;
	z-index: 1030;
	opacity: 1 !important;
	min-height: 100vh;
  	> *{
		width: inherit;
		margin: 0;
		overflow: hidden;
		height: 100%;
  	}
  	~ .app-aside{
  		z-index: 1020;
  	}
}

.app-content{
	-ms-flex: 1;
    flex: 1;
	display: -ms-flexbox!important;
    display: flex!important;
    -ms-flex-direction: column;
    flex-direction: column;
	box-shadow: none;
}

.content-header{
	z-index: 1010;
}

.content-main{
	
}

.content-footer{
	margin-top: auto;
}

.is-fullscreen,
.fixed-content{
	height: 100%;
	.app{
		max-height: 100%;
		-ms-flex: 1;
    	flex: 1;
	}
	.app-header ~ .app{
  		max-height: auto;
  	}
	.app-content{
		.content-main{
			overflow: auto;
			-webkit-overflow-scrolling: touch;
		}
		.content-footer{
			display: none;
		}
	}
	.app-aside{
	  	> *{
			position: fixed;
	  	}
  	}
  	.app-header ~ .app .app-aside > *{
  		max-height: calc(100% - #{$navbar-height});
  	}
}
.fixed-aside {
	.app-aside{
	  	> *{
			position: fixed;
	  	}
  	}
  	.app-header ~ .app .app-aside > *{
  		max-height: calc(100% - #{$navbar-height});
  	}
}

@include media-breakpoint-up(lg) {
	.app-aside {
		display: block !important;
		&,
		.scroll{
			width: $aside-width;
		}
		&.folded{
			width: $aside-folded-width;
			&.md{
				width: $aside-folded-md-width;
			}
		}

		.hide-scroll{
		  -ms-flex: 1;
  		  flex: 1;
		  width: auto;
		  margin-right: -17px;
		  overflow-x: hidden;
		  overflow-y: scroll;
		  -webkit-overflow-scrolling:touch;
		}
	}
}

@include media-breakpoint-down(md) {
	.app-aside {
		width: 100%;
		height: 100%;
		top: 0;
		position: fixed;
		z-index: 1050;
		display: none;
		background: transparent !important;
		> *{
			position: fixed;
			height: 100%;
			background-color: inherit;
			@include transition-transform(0.4s cubic-bezier(0.25, 0.8, 0.25, 1));
      		@include translate3d(-100%, 0, 0);
			width: $aside-slide-width;
			max-height: 100% !important;
		}
		&.show{
			> div{
				@include translate3d(0, 0, 0);
			}
		}
	}
}

@media print {
	.app-aside,
	.app-header,
	.content-header,
	.content-footer,
	.setting{
		display: none !important;
	}
}
