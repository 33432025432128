/*
List
*/
.list{
	padding-left: 0;
	padding-right: 0;
}

.list-item{
	display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-align: stretch;
    align-items: stretch;
    padding: 0.75rem 0.5rem;
    position: relative;
    > *{
	    margin-left: 0.5rem;
		margin-right: 0.5rem;
    }
	&:last-child .list-body:after{
		border: none;
	}
	&.active{
		background-color: $active-color;
	}
}

.list-body{
	-ms-flex: 1;
    flex: 1;
	h3{
		font-size: 16px;
		margin: 0 0 3px 0;
		font-weight: normal;
	}
	&:after{
		content: "";
		position: absolute;
		left: 0;
		right: 0;
		border-bottom: 1px solid $border-color-lt;
		bottom: 0;
	}
	.inset &{
		position: relative;
		&:after{
			bottom: -0.75rem;
		}
	}
	.no-border &:after{
		display: none;
	}
}
