/*
Timeline
*/
.timeline{
	margin: 0;
	padding: 0;
}
.tl-item{
	display: block;
	@include clearfix();
}
.visible-left{
	display: none;
}
.tl-wrap{
	display: block;
	margin-left: 6em;
	padding: 15px 0 15px 20px;
	border-style: solid;
	border-color: $border-color;
	border-width: 0 0 0 2px;
	@include clearfix();
	&:before{
		position: relative;
		content: "";
		float: left;
		top: 13px;
		margin-left: -26px;
		width: 10px;
		height: 10px;
		border-color: inherit;
		border-width: 3px;
		border-radius: 50%;
		border-style: solid;
		background: $light;
		box-shadow: 0 0 0 4px $body-bg;	
		.dark &	{
			box-shadow: 0 0 0 4px $dark;	
		}
	}
}

.tl-date{
	position: relative;
	top: 8px;
	float: left;
	margin-left: -8.5em;
	display: block;
	width: 5.5em;
	text-align: right;
}

.tl-content{
	display: inline-block;
	position: relative;
	padding: 0.5rem 1rem;
	&.block{
		display: block;
		width: 100%;
	}
}

.tl-header{
	display: block;
	width: 12em;
	text-align: center;
	margin-left: 1px;
}

.timeline-center{
	.tl-item{
		margin-left: 50%;
		.tl-wrap{
			margin-left: -2px;
		}
	}
	.tl-header{
		width: auto;
		margin-left: -1px;
	}
	.tl-left{
		margin-left: 0;
		margin-right: 50%;
		.hidden-left{
			display: none !important;
		}
		.visible-left{
			display: inherit;
		}		
		.tl-wrap{
			float: right;
			margin-right: 0px;
			border-left-width: 0;
			border-right-width: 2px;
			padding-left: 0;
			padding-right: 20px;
			&:before{
				float: right;
				margin-left: 0;
				margin-right: -26px;
			}
		}
		.tl-date{
			float: right;
			margin-left: 0;
			margin-right: -8.5em;
			text-align: left;	
		}
	}
}
